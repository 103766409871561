<template>
  <div class="StepGroupQuizIntroView">
    <StepBackground :image="backgroundImage" />

    <div
      ref="scrollContainer"
      class="scrollContainer">
      <div
        class="content"
        :class="{ hide: isReviewing }">
        <div class="spacer" />

        <QuizResult
          :completed="realCompleted"
          :title="$t('title')"
          :subtitle="journeyName"
          :score="quizProgress"
          icon="flag-with-checkmark"
          :buttons="quizResultButtons"
          @action="handleQuizResultAction" />

        <div class="spacer" />
      </div>

      <MemosReviewScreen
        v-if="isReviewing"
        :items="itemsForReview"
        class="reviewSection"
        @request-close="endReview()"
        @request-quiz="
          endReview();
          continueQuiz();
        " />
    </div>

    <MainBackButton
      class="backButton"
      @request-exit="exit()" />
  </div>
</template>

<translations>
  back: 'Back to Article'
  back_no: 'Tilbake til artikkelen'

  title: 'Journey quiz'
  title_no: 'Reisequiz'

  startQuiz: 'Start Quiz'
  startQuiz_no: 'Start quiz'

  redoQuiz: 'Redo Quiz'
  redoQuiz_no: 'Quiz på nytt'

  continueQuiz: 'Complete the quiz'
  continueQuiz_no: 'Fullfør quiz'

  resetQuiz: 'Reset Quiz'
  resetQuiz_no: 'Nullstill quiz'

  continue: 'Continue'
  continue_no: 'Fortsett'

  reviewButton: 'Memorize mistakes'
  reviewButton_no: 'Memorer feilene'
</translations>

<script>
import ThemeMixin from '../theme-mixin';
import StepBackground from '../components/StepBackground';
import MemosReviewScreen from '../components/MemosReviewScreen';
import QuizResult from '@/modules/course/components/QuizResult';
import MainBackButton from '@/modules/course/components/MainBackButton';
import { getStepGroupTitle } from '@/utils';
import { TimelineLite } from 'gsap';

function supportsQuizState(generatorName) {
    return ['standardStepGroupQuiz', 'historyJourneyQuiz', 'chemistryStepGroupQuiz'].indexOf(generatorName) !== -1;
}

export default {
    components: { StepBackground, MemosReviewScreen, QuizResult, MainBackButton },
    mixins: [ThemeMixin],
    props: {
        courseId: {
            type: String,
            required: true
        },
        stepGroupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isReviewing: false
        };
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        },
        backgroundImage() {
            return this.course.bannerImage || null;
        },
        stepGroup() {
            return this.$store.state.moduleCourse.stepGroups[this.stepGroupId];
        },
        journeyName() {
            return getStepGroupTitle(this.stepGroup, this.$t.bind(this), this.$tc.bind(this));
        },
        quizState() {
            return this.$store.getters['moduleCourse/getStepGroupQuizState'](this.stepGroupId);
        },
        itemsForReview() {
            if (!this.quizState) {
                return [];
            }
            return this.quizState.failedItems
                .filter(x => x.source)
                .map(x => {
                    return x;
                });
        },
        quizProgress() {
            if (supportsQuizState(this.stepGroup.quiz.generator || 'standardStepGroupQuiz')) {
                return this.quizState && this.quizState.totalCount
                    ? this.quizState.correctCount / this.quizState.totalCount
                    : 0;
            } else {
                return this.stepGroup.quiz.done ? 1 : 0;
            }
        },
        inProgress() {
            return this.quizProgress < 1 && this.quizState;
        },
        realCompleted() {
            return !!this.stepGroup.quiz.done;
        },
        completed() {
            return this.quizProgress === 1;
        },
        ready() {
            return !this.quizState; // this.quizProgress === 0
        },
        quizResultButtons() {
            if (this.completed) {
                return [
                    {
                        label: this.$t('continue'),
                        action: 'exit'
                    },
                    {
                        label: this.$t('redoQuiz'),
                        action: 'redoQuiz'
                    }
                ];
            } else if (this.ready) {
                return [
                    {
                        label: this.$t('startQuiz'),
                        action: 'startQuiz'
                    }
                ];
            } else if (this.inProgress) {
                const buttons = [];
                if (this.itemsForReview.length) {
                    buttons.push({
                        label: this.$t('reviewButton'),
                        action: 'startReview'
                    });
                }
                buttons.push({
                    label: this.$t('continueQuiz'),
                    action: 'continueQuiz'
                });
                return buttons;
            } else {
                return [];
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('moduleCourse/setLastJourneyId', { journeyId: this.stepGroupId });
        if (to.name !== 'StepGroupQuizView') {
            this.resetQuiz();
        }
        next();
    },
    methods: {
        resetQuiz() {
            this.$store.dispatch('moduleCourse/resetStepGroupQuizProgress', { stepGroupId: this.stepGroupId });
        },
        redoQuiz() {
            this.resetQuiz();
            this.startQuiz();
        },
        continueQuiz() {
            this.startQuiz();
        },
        startQuiz() {
            this.$router.push({
                name: 'StepGroupQuizView',
                params: { courseId: this.courseId, stepGroupId: this.stepGroupId }
            });
        },
        async startReview() {
            if (this.quizState) {
                this.isReviewing = true;
                await this.$nextTick();
                const tl = new TimelineLite();
                tl.to(this.$refs.scrollContainer, 1, {
                    scrollTop: this.$refs.scrollContainer.clientHeight
                });
            }
        },
        handleQuizResultAction(action) {
            const func = this[action];
            if (func) {
                func();
            }
        },
        endReview() {
            this.isReviewing = false;
        },
        exit() {
            const course = this.$store.state.moduleCourse.courses[this.courseId];
            if (course.lifeskillNumber) {
                this.$router.push({
                    name: 'DashboardLifeskillViewWithTab',
                    params: { lifeskillId: course.lifeskillNumber, tabName: 'theory' }
                });
            } else {
                this.$router.push({
                    name: 'CourseHomeView',
                    params: { courseId: this.courseId }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.StepGroupQuizIntroView {
    display: flex;
    flex-direction: column;
}

.scrollContainer {
    height: 100%;
    overflow-y: auto;
}
.reviewSection {
    position: relative;
    min-height: 100vh;
}

.content {
    position: relative;

    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.QuizResult {
    position: relative;
}
.spacer {
    flex: 1;
}

.content {
    padding-bottom: 3em;
}

.backButton {
    position: absolute;
    margin-left: 0.5em;
    margin-top: 0.5em;
}

.spacer {
    min-height: 1em;
    height: 3em;
    flex: 1;
}
</style>
