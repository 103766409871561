<template>
  <div class="MemosReviewScreen">
    <div class="spacerTop" />
    <div class="items">
      <div
        v-for="(item, i) of items"
        :key="i"
        class="Item">
        <div class="quizContext">
          <div class="question">
            {{ item.source.question }}
          </div>
          <div
            v-if="item.answer"
            class="submittedAnswer">
            {{ item.answer }}
          </div>
        </div>
        <MemoCard
          :key="item.source.memoId"
          class="card"
          :memo-id="item.source.memoId"
          :no-games="true"
          :start-with-story-mode="true" />
      </div>
    </div>
    <div class="spacerBottom" />
    <div
      class="startQuizButton --global-clickable"
      @click="$emit('request-quiz')">
      {{ $t('continueQuiz') }}
    </div>
  </div>
</template>

<script>
import MemoCard from '@/modules/course/components/MemoCard';

export default {
    components: { MemoCard },
    props: {
        items: {
            type: Array,
            required: true
        }
    }
};
</script>

<translations>
  continueQuiz: 'Complete the quiz'
  continueQuiz_no: 'Fullfør quiz'
</translations>

<style lang="scss" scoped>
.MemosReviewScreen {
    color: white;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.spacerTop {
    height: 1em;
    min-height: 1em;
}

.Item {
    &:not(:first-child) {
        margin-top: 4em;
    }
}

.quizContext {
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.question {
    text-align: center;
    font-weight: 600;
    font-size: 120%;
    color: rgba(white, 0.8);
}
.submittedAnswer {
    color: rgba(white, 0.9);
    text-align: center;
    margin-top: 1em;
    background-color: red;
    padding: 0.3em 1em;
    border-radius: 0.3em;
    max-width: 30em;
}
.card {
    margin-top: 1.5em;
}

.spacer {
    flex: 1;
}

.spacerBottom {
    min-height: 4em;
    flex: 1;
}

.items {
    width: 40em;
    max-width: calc(100% - 2em);
}

.startQuizButton {
    padding: 0.7em 1em;
    background-color: white;
    border-radius: 2em;
    color: black;
    text-align: center;

    margin-bottom: 2em;
}

.backButton {
    $size: 3em;
    background-color: rgba(white, 0.5);
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1em;
    right: 1em;
    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(black, 0.9);
    }
}
</style>
